import axios from 'axios';
import qs from 'qs';
import { makeRandomString } from '../util';
import useBugsnag from 'hooks/useBugsnag';

const { notifyBugsnag } = useBugsnag();

const client = require('./request/client');

const USER_URL = `${process.env.EP_URL}/client_api/user.php`;
const SIGNON_URL = `${process.env.EP_URL}/client_api/signon.php`;

axios.defaults.withCredentials = true;
const createTempUser = async ({ firstName, lastName, email, password, countryCode, phoneNumber }) => {
  const countryCodeWithoutPlus = countryCode.substr(1);
  const phoneNumberWithoutPlus = phoneNumber.substr(1);

  const requestPayload = {
    first_name: firstName,
    last_name: lastName,
    email,
    // mobile_number: `${countryCodeWithoutPlus}${phoneNumber}`,
    mobile_number: `${phoneNumberWithoutPlus}`,
    password,
    password_confirmation: password,
    request: 'create_temp_user',
    send_verification_code: 'true',
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(requestPayload),
    withCredentials: true,
    url: USER_URL,
  };

  return await axios(requestOptions)
    .then(response => {
      if (response.data.status !== 0) {
          const error = new Error(response.data.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST' } });
      return error;


      }
      return response.data.srvrtid;
    })
    .catch(error => {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST' } });
      return error;


    });
};

const createUser = async ({
  firstName,
  lastName,
  email,
  password,
  countryCode,
  phoneNumber,
  referralCode,
  friendReferralCode,
  verificationCode,
  tempUserSrvrtid,
}) => {
  // const countryCodeWithoutPlus = countryCode.substr(1);
  const phoneNumberWithoutPlus = phoneNumber.substr(1);

  let trimmedPhoneNumber = phoneNumber;
  if (phoneNumber.charAt(0) === '0') {
    trimmedPhoneNumber = trimmedPhoneNumber.substr(1);
  }

  const requestPayload = {
    first_name: firstName,
    last_name: lastName,
    email,
    // mobile_number: `${countryCodeWithoutPlus}${trimmedPhoneNumber}`,
    mobile_number: `${phoneNumberWithoutPlus}`,
    password,
    password_confirmation: password,
    sms_activation_code: verificationCode,
    referral_code: referralCode === 'friend' ? friendReferralCode : referralCode,
    request: 'create_user',
    temp_user_srvrtid: tempUserSrvrtid,
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(requestPayload),
    withCredentials: true,
    url: USER_URL,
  };

  return await axios(requestOptions)
    .then(response => {
      if (response.data.status !== 0) {
          const error = new Error(response.data.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST' } });
      return error;


      }
      return 'Ok';
    })
    .catch(error => {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST' } });
      return null;


    });
};

const login = async ({ email, password }) => {
  const requestPayload = {
    username: email,
    password,
    request: 'signon',
    appid: 'expresspay API',
    send: 'Login',
    clientuid: email,
    useruid: email,
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(requestPayload),
    url: SIGNON_URL,
  };

  try {
    const response = await axios(requestOptions);
    if (response.data.status !== 0) {
        const error = new Error(response.data.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST' } });
      return error;


    }

    const { data } = response;
    const { transfercard, wallet } = data;
    const csrfToken = data['Csrf-Request-Token'];

    const user = {
      ...data.output,
      transfercard,
      wallet,
      csrfToken,
    };
    return user;
  } catch (error) {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST' } });
      return error;


  }
};

const autoSignonGuestUser = async ({
  merchantServiceSrvrtid,
  firstName,
  lastName,
  countryCode,
  phoneNumber,
  email,
  amount,
  g_recaptcha_response,
}) => {
  let newPhoneNumber = phoneNumber;
  if (phoneNumber.startsWith('0')) {
    newPhoneNumber = phoneNumber.slice(1);
  }

  const phoneNumberWithCountryCode = `${countryCode.slice(1)}${newPhoneNumber}`;
  const requestPayload = {
    request: 'signon_guest_user',
    trnamt: amount,
    merchant_service_srvrtid: merchantServiceSrvrtid,
    service_description: '',
    payacct: `${firstName} ${lastName}`,
    description: phoneNumberWithCountryCode,
    phone_number: phoneNumberWithCountryCode,
    firstname: firstName,
    lastname: lastName,
    email,
    'g-recaptcha-response': g_recaptcha_response,
  };

  return await client
    .sendRequest(USER_URL, requestPayload)
    .then(response => {
      if (response.status !== 0) {
          const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST' } });
      return error;


      } else {
        const { data } = response;
        const { transfercard, wallet } = data;
        const csrfToken = data['Csrf-Request-Token'];

        const user = {
          ...data.output,
          transfercard,
          wallet,
          csrfToken,
        };

        return user;
      }
    })
    .catch(error => {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST' } });
      return error;


    });
};

const isLoggedIn = async () => {
  const requestPayload = {
    request: 'is_user_logged_in',
  };

  return await client.sendRequest(SIGNON_URL, requestPayload).then(response => {
    if (response.status !== 0) {
      throw new Error(response.message);
    } else {
      return response.output;
    }
  });
};

const uploadIDForVerification = async (imageFile, idType) => {
  const requestPayload = {
    request: 'upload_id_pic',
    id_type: idType,
    file_upload: imageFile,
  };

  return await client.uploadFile(USER_URL, requestPayload).then(response => {
    if (response.status !== 0) {
      throw new Error(response.message);
    } else {
      return response.output;
    }
  });
};

const getManagedWallets = async () => {
  const requestPayload = {
    request: 'get_my_managed_wallets',
  };

  return await client.sendRequest(USER_URL, requestPayload).then(response => {
    if (response === null) throw new Error('not signed in');

    if (response?.status !== 0) {
      throw new Error(response?.message);
    } else {
      return response?.output;
    }
  });
};

const forgotPassword = async email => {
  const requestPayload = {
    email: email,
    send: 'Reset Password',
    request: 'send_password_reset_email',
    type: 'email',
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(requestPayload),
    url: USER_URL,
  };

  try {
    const response = await axios(requestOptions);
    if (response.data.status !== 0) {
        const error = new Error(response.data.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response } } });
      return error;


    }

    const { data } = response;

    return data;
  } catch (error) {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response: error.response } } });
      return error;


  }
};

const resetPassword = async ({ emvc, password }) => {
  const requestPayload = {
    request: 'send_password_phone_code',
    emvc,
    password,
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(requestPayload),
    url: USER_URL,
  };

  try {
    const response = await axios(requestOptions);
    if (response.data.status !== 0) {
        const error = new Error(response.data.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST' } });
      return error;


    }

    const { data } = response;

    return data;
  } catch (error) {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST' } });
      return error;


  }
};

const sendVerificationCode = async (code, password, emvc) => {
  /*
  signon.php
  request: reset_password
  password: hithere_
  password_confirmation: hithere_
  verification_code: 8908767
  emvc: 721260b50ee5ae3e62.7910508360b50
  */

  const requestPayload = {
    request: 'reset_password',
    emvc: emvc,
    password: password,
    password_confirmation: password,
    verification_code: code,
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(requestPayload),
    url: SIGNON_URL,
  };

  try {
    const response = await axios(requestOptions);
    if (response.data.status !== 0) {
        const error = new Error(response.data.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST' } });
      return error;


    }

    const { data } = response;

    return data;
  } catch (error) {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST' } });
      return error;


  }
};

const logout = async () => {
  const requestPayload = {
    request: 'signout',
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(requestPayload),
    url: SIGNON_URL,
  };

  try {
    const response = await axios(requestOptions);
    if (response.data.status !== 0) {
        const error = new Error(response.data.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response } } });
      return error;


    }
  } catch (error) {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response: error.response } } });
      return error;


  }
};

const uploadProfilePic = async file => {
  const dataUrl = await toBase64(file);

  const requestPayload = {
    request: 'upload_profile_pic',
    file_upload: file,
  };

  const formData = new FormData();
  for (let key in requestPayload) {
    formData.append(key, requestPayload[key]);
  }

  const requestOptions = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    // data: qs.stringify(formData),
    data: formData,
    url: USER_URL,
  };

  try {
    const response = await axios(requestOptions);
    if (response.data.status !== 0) {
        const error = new Error(response.data.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response } } });
      return error;


    }

    const { data } = response;

    return { data, dataUrl };
  } catch (error) {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response: error.response } } });
      return error;


  }
};

const getProfile = async () => {
  const getProfileBody = [
    {
      request: 'get_profile',
      url: '',
      key: 'foo',
      params: {
        api: '4.1',
        clienttid: 'hmpmqffablcrsrppogso',
        key: '',
        request: 'get_profile',
      },
    },
  ];

  try {
    const requestPayload = {
      request: 'aggregate_signon_request',
      body: JSON.stringify(getProfileBody),
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(requestPayload),
      url: SIGNON_URL,
    };

    const { data } = await axios(requestOptions);
    console.log('getProfile::', data);

    const result = data[0]?.result;

    return result;
  } catch (error) {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST' } });


    console.log('error.message', error.message);
    return error;
  }
};

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });



  const sendAppLink = async (email = "") => {
    const requestPayload = {
        request: "send_app_link",
        dest: email,
      }
    
  
    const requestOptions = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(requestPayload),
      url: USER_URL,
    };
  
    try {
      const response = await axios(requestOptions);
      if (response.data.status !== 0) {
        return new Error(response.data.message);
      }
      const { data } = response
      return data ;
  
      
    } catch (error) {
      return error;
    }
  };

  const verifyUser = async (email_verification_number) => {
    const requestPayload = {
        request: "verify_email",
        email_verification_number,
      }
    
  
    const requestOptions = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(requestPayload),
      url: USER_URL,
    };
  
    try {
      const response = await axios(requestOptions);
      console.log("response::", response)
      const { data } = response || {};
      return data ;
      
    } catch (error) {
      notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response: error.response } } });
      console.log('error.message', error.message);
      throw error;
    }
  };


  const getUserNotificationPreference = async () => {
    const requestPayload = {
      request: "get_user_notfication_preference",
    }
  const requestOptions = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(requestPayload),
    url: USER_URL,
  };
  
    try {

      const response = await axios(requestOptions);
      // console.log("response for getUserNotificationPreference::", response)
      const { data } = response || {};
  
      if (data?.status !== 0) {
        const error = new Error(response.data.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response } } });
        throw error;
      }
  
      const { output } = data;
      return output;
  
    } catch (error) {
      notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response: error.response } } });
      throw error;
    }
  };

  const updateUserNotificationsPreference = async (payload) => {
    const requestPayload = {
      request: "update_user_notfication_preference",
      ...payload
    } 

    const requestOptions = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(requestPayload),
      url: USER_URL,
    };

    try {

      const response = await axios(requestOptions);
      const { data } = response || {};
      const { status, message } = data || {};
  
      if (status !== 0) {
        const error = new Error(response.data.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response } } });
        throw error;
      }
  
      
      return message;
  
    } catch (error) {
      notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response: error.response } } });
      return error;
    }

  }
  const deleteUserAccount = async (email, password) => {
    const requestPayload = {
      request : "delete_user_account",
      username : email,
      password: password,
    } 

    const requestOptions = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(requestPayload),
      url: USER_URL,
    };

    try {

      const response = await axios(requestOptions);
      const { data } = response || {};
      const { status, message } = data || {};
  
      if (status !== 0) {
        const error = new Error(response.data.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response } } });
        throw error;
      }
      return message;
  
    } catch (error) {
      notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response: error.response } } });
      throw error;
    }

  }
  
  

export default {
  createTempUser,
  createUser,
  login,
  autoSignonGuestUser,
  isLoggedIn,
  uploadIDForVerification,
  getManagedWallets,
  forgotPassword,
  resetPassword,
  sendVerificationCode,
  logout,
  uploadProfilePic,
  getProfile,
  sendAppLink,
  verifyUser,
  getUserNotificationPreference,
  updateUserNotificationsPreference,
  deleteUserAccount
};

